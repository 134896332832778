import React from "react";
import { Route, Routes} from "react-router-dom";


import StreetliftingCompanion from "./pages/StreetliftingCompanion";



function App() {
	return (
		<Routes>
			<Route path="/" Component={StreetliftingCompanion} />
		</Routes>
	);
}

export default App;
