import React from "react";
import Footer from "../components/Footer";
import MainPage from "../components/MainPage";
import "../css/StreetliftingCompanion.css";
function StreetliftingCompanion() {
	return (
		<>
			<MainPage />
			<Footer />
		</>
	);
}

export default StreetliftingCompanion;
